var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TextField',{class:_vm.$style.searchForm,attrs:{"label":_vm.type === _vm.EditableCoursesRequest.CoursesTypes.PAID ? 'Поиск по коду, номенклатуре, цене' : 'Поиск по номенклатуре',"prepend-inner-icon":"mdi-magnify","dense":"","hide-details":"","clearable":"","blue_icon":"","inputmode":"search","enterkeyhint":"search","type":"search"},on:{"click:clear":_vm.clearSearch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.clearSearch.apply(null, arguments)},"input":function($event){return _vm.updateSearch($event)},"onPaste":_vm.onPaste},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('Table',{attrs:{"headers":_vm.type === _vm.EditableCoursesRequest.CoursesTypes.PAID ? _vm.paidCoursesCols : _vm.freeCoursesCols,"items":_vm.items,"loading":_vm.loading,"custom-sort":_vm.prevent_sort,"not_resizeble":true,"no-data-text":"Курсы, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"click:row":_vm.toggleCheckbox},scopedSlots:_vm._u([{key:"header.select",fn:function(){return [_c('v-checkbox',{attrs:{"input-value":_vm.selectedCourses.length > 0,"indeterminate":_vm.selectedCourses.length > 0},on:{"change":_vm.selectAll}})]},proxy:true},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.$style.checkbox},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","input-value":_vm.selectedCourses.includes(item)},on:{"click":function($event){$event.stopPropagation();return _vm.addCourse($event, item)}}})],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.isMobile ? [_vm.$style.mobile_row] : []},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.$style.title},'div',attrs,false),on),[_c('span',{class:[_vm.isMobile ? 'overflow-text' : '', _vm.$style.titleText]},[_vm._v(" "+_vm._s(item.title)+" ")])])]}}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.priceCell},[_c('div',{class:_vm.$style.prices},[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.actual.base.rub))+" ")])]),(item.prices.actual.base.rub > 0)?_c('PriceTooltips',{attrs:{"prices":item.prices.actual}}):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{class:_vm.$style.pagination,attrs:{"pagination_props":_vm.paginationProps,"hide_page_size":""},on:{"change_page":function($event){return _vm.updatePagination('current_page', $event)},"page_size_changed":function($event){return _vm.updatePagination('limit', $event)}}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }